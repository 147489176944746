// require("typeface-montserrat");
require("./src/css/pageStyle.css");

// export function shouldUpdateScroll(prevRouterProps, { location }) {
//   window.scrollTo(0, 0)
//   const body = document.getElementsByTagName('body')[0]
//   // add smooth scrolling
//   body.style.scrollBehavior = 'smooth'
//   body.scrollTop = 0
//   return false
// }
