import React from "react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { gsap } from "gsap"
import { useRef, useEffect } from "react"
import "../css/nav.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"

gsap.registerPlugin(ScrollTrigger)

const TopMenu = () => {
  const navRef = useRef(null)
  const navBackgroundRef = useRef(null)
  const navLogoRef = useRef(null)

  useEffect(() => {
    const navBackground = navBackgroundRef.current
    const navLogo = navLogoRef.current

    const changeMenuBackground = () => {
      if (window.scrollY > 100) {
        navBackground.classList.add("nav__background--display")
      } else {
        navBackground.classList.remove("nav__background--display")
      }
    }

    const displayLogo = () => {
      if (window.scrollY > 100) {
        setTimeout(() => {
          navLogo.classList.add("nav__logo--display")
        }, 200)
      } else {
        navLogo.classList.remove("nav__logo--display")
      }
    }

    window.addEventListener("scroll", () => {
      changeMenuBackground()
      displayLogo()
    })
    return () => {
      window.removeEventListener("scroll", changeMenuBackground)
    }
  }, [])

  const handleMenuIconClick = () => {
    const nav = navRef.current
    nav.classList.toggle("nav--open")
  }

  const handleCloseMenuAfterClick = () => {
    const nav = navRef.current
    nav.classList.remove("nav--open")
  }

  return (
    <nav key={"navKey"} ref={navRef} className="nav">
      <div
        onClick={handleMenuIconClick}
        onKeyDown={handleMenuIconClick}
        role="button"
        tabIndex={0}
        className="nav__icon"
      >
        Menu
      </div>
      <div ref={navBackgroundRef} className="nav__background">
        <a
          ref={navLogoRef}
          className="nav__logo"
          href="https://santiagodesign.pl/"
        >
          <img
            src="https://jzlds.pl/wp-content/uploads/2023/12/logo.png"
            alt="logo"
          ></img>
        </a>
      </div>
      <div className="nav__items">
        {/* <Link to="/">Strona główna</Link> <div 
  onClick={handleMenuIconClick} 
  onKeyDown={handleMenuIconClick} 
  role="button" 
  tabIndex={0} 
  className="nav__icon"
>
  Menu
</div>*/}
        <AniLink
          paintDrip
          to="/"
          hex="#000000"
          onClick={handleCloseMenuAfterClick}
        >
          Strona główna
        </AniLink>
        {/* <Link to="/o-mnie">O mnie</Link> */}
        <AniLink
          paintDrip
          to="/o-mnie"
          hex="#000000"
          onClick={handleCloseMenuAfterClick}
        >
          O mnie
        </AniLink>
        {/* <Link to="/portfolio">Portfolio</Link> */}
        <AniLink
          paintDrip
          to="/portfolio"
          hex="#000000"
          onClick={handleCloseMenuAfterClick}
        >
          Portfolio
        </AniLink>
        {/* <Link to="publikacje">Publikacje</Link> */}
        {/* <Link to="/blog">Blog</Link> */}
        <AniLink
          paintDrip
          to="/kontakt"
          hex="#000000"
          onClick={handleCloseMenuAfterClick}
        >
          Kontakt
        </AniLink>
        {/* <Link to="/kontakt">Kontakt</Link> */}
      </div>
    </nav>
  )
}

export default TopMenu
