import React from "react"
import TopMenu from "../components/nav"

function NavFooterLayout({ children }) {
  return (
    <div className="page-wrapper">
      <TopMenu />
      {children}
    </div>
  )
}

export default NavFooterLayout
